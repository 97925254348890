import React, { useEffect, useState, useMemo } from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link, graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";
import { MdMonitor } from "react-icons/md";
import { AiOutlineControl } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Slider from "../components/common/Slider";
import { Container, Column, FlexContainer, Button } from "../components/common";

import stripeBackground from "../images/betheme/images/stripes/stripes_10_b.png";

import image3_1 from "../images/3-1.jpg";
import image1_2 from "../images/1-2.jpg";
import image2_1 from "../images/2-1.jpg";
import image4_1 from "../images/4-1.jpg";

import image4 from "../images/4.jpg";
import image3 from "../images/3.jpg";
import image1_1 from "../images/1-1.jpg";

import image1_3 from "../images/1-3.jpg";
import image5_2 from "../images/5-2.jpg";
import image4_2 from "../images/4-2.jpg";
import image2_2 from "../images/2-2.jpg";

export const query = graphql`
  query RoomsPageQuery($lang: String) {
    prismicMisc(lang: { eq: $lang }) {
      data {
        room
        home
        contact
      }
    }
    prismicRooms(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        more_photo
        facebook
        body {
          ... on PrismicRoomsDataBodyRoomTypes {
            id
            items {
              title {
                text
                html
                richText
              }
              air_con
              bedrooms
              gross_sq_ft
              guests
              tv
            }
          }
          ... on PrismicRoomsDataBodyOgInfo {
            primary {
              ogDescription: description
              ogTitle: title
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Section = styled.div`
  width: 100%;
  &:not(:last-child) {
    border-bottom: 2px solid ${props => props.theme.colors.orange};
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  line-height: 42px;
  font-size: 42px;
  text-align: center;
  margin-bottom: 16px;
`;

const IconDescription = styled.div`
  text-align: center;
`;

const Icon = styled.span`
  font-size: 1.5rem;
  color: #000000;
  margin-right: 4px;
`;

const ButtonIcon = styled.span`
  margin-right: 4px;
`;

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 4px 20px;
`;

const DescriptionBox = styled.div`
  padding: 40px 20px 20px;
  background: url(${stripeBackground});
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 15px;
  line-height: 20px;

  @media (min-width: 992px) {
    margin-top: 20px;
  }
`;

const ButtonContainer = styled(FlexContainer)`
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
  gap: 24px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const RoomPage = ({ data, pageContext }) => {
  const { body, more_photo, facebook } = data.prismicRooms.data;
  const meta = body[3];
  const roomTypes = body.slice(0, 3).map(b => b.items[0]);
  const [lightboxImageIndex, setLightboxImageIndex] = React.useState(0);
  const [lightboxForRoomTypeIndex, setLightboxForRoomTypeIndex] =
    React.useState(undefined);

  const infos = React.useMemo(
    () => [
      {
        images: [image3_1, image1_2, image2_1, image4_1],
        roomType: roomTypes[0],
        lightboxImages: [
          "../../images/lemanndi-room-b/image5.jpg",
          "../../images/lemanndi-room-b/image4.jpg",
          "../../images/lemanndi-room-b/image7.jpg",
          "../../images/lemanndi-room-b/image6.jpg",
          "../../images/lemanndi-room-b/image2.jpg",
          "../../images/lemanndi-room-b/image1.jpg",
        ],
        fbLink:
          "https://www.facebook.com/LeManndi/photos/ms.c.eJw1jMENwDAMAjeqHEhwvP9iVe3yQqcDFGSdVVKigEfNyOa1f~_bhF0l7NF~;ZV0QINIOzv~;bzF~;7bnD5edvgZxg~-~-.bps.a.603391800006595.1073741831.459501827728927/603395196672922/?type=3&theater",
      },
      {
        images: [image4, image3, image1_1],
        roomType: roomTypes[1],
        lightboxImages: [
          "../../images/lemanndi-room-a/image7.jpg",
          "../../images/lemanndi-room-a/image6.jpg",
          "../../images/lemanndi-room-a/image9.jpg",
          "../../images/lemanndi-room-a/image8.jpg",
          "../../images/lemanndi-room-a/image2.jpg",
          "../../images/lemanndi-room-a/image1.jpg",
          "../../images/lemanndi-room-a/image4.jpg",
          "../../images/lemanndi-room-a/image3.jpg",
        ],
        fbLink:
          "https://www.facebook.com/LeManndi/photos/a.603394050006370.1073741832.459501827728927/603394126673029/?type=3&theater",
      },
      {
        images: [image1_3, image5_2, image4_2, image2_2],
        roomType: roomTypes[2],
        lightboxImages: [
          "../../images/lemanndi-room-c/image8.jpg",
          "../../images/lemanndi-room-c/image7.jpg",
          "../../images/lemanndi-room-c/image10.jpg",
          "../../images/lemanndi-room-c/image9.jpg",
          "../../images/lemanndi-room-c/image2.jpg",
          "../../images/lemanndi-room-c/image1.jpg",
          "../../images/lemanndi-room-c/image4.jpg",
          "../../images/lemanndi-room-c/image3.jpg",
          "../../images/lemanndi-room-c/image5.jpg",
        ],
        fbLink:
          "https://www.facebook.com/LeManndi/photos/ms.c.eJw9jsENADEIwzY6UVIC7L~;YqVD6QpatCAqQZolzdn4spokIdcllt~;LA5dDjVw67k67BPYzubfr2Np6rON5~_9h7Hs~;~;RYa3e8wc7QSOq.bps.a.603395576672884.1073741833.459501827728927/603395593339549/?type=3&theater",
      },
    ],
    [roomTypes]
  );

  const images = useMemo(
    () =>
      lightboxForRoomTypeIndex !== undefined
        ? infos[lightboxForRoomTypeIndex].lightboxImages
        : [],
    [lightboxForRoomTypeIndex]
  );

  return (
    <Layout lang={pageContext.lang} misc={data.prismicMisc.data}>
      <Seo
        title={meta.primary.ogTitle}
        description={meta.primary.ogDescription}
        ogImageUrl={meta.primary.image.url}
      />
      {infos.map(({ images, roomType, fbLink }, index) => {
        return (
          <Section>
            <Slider>
              {images.map(src => {
                return (
                  <img
                    src={src}
                    formats={["jpg"]}
                    alt="Welcome Slider"
                    style={{ marginBottom: `1.45rem` }}
                  />
                );
              })}
            </Slider>
            <Container>
              <Title>{roomType.title.text}</Title>
              <IconDescription>
                <IconContainer className="big">
                  <Icon as={AiOutlineControl} />
                  {roomType.air_con}
                </IconContainer>
                <IconContainer className="big">
                  <Icon as={MdMonitor} />
                  {roomType.tv}
                </IconContainer>
              </IconDescription>
              <FlexContainer>
                <Column size={3} gap={"20px"}>
                  <DescriptionBox>{roomType.guests}</DescriptionBox>
                </Column>
                <Column size={3} gap={"20px"}>
                  <DescriptionBox>{roomType.bedrooms}</DescriptionBox>
                </Column>
                <Column size={3} gap={"20px"}>
                  <DescriptionBox>{roomType.gross_sq_ft}</DescriptionBox>
                </Column>
              </FlexContainer>
              <ButtonContainer>
                <Button
                  color="orange"
                  onClick={() => setLightboxForRoomTypeIndex(index)}
                >
                  {more_photo}
                </Button>
                <Button as="a" color="outline-orange" href={fbLink}>
                  <ButtonIcon as={FaFacebookF} />
                  {facebook}
                </Button>
              </ButtonContainer>
            </Container>
          </Section>
        );
      })}
      {lightboxForRoomTypeIndex !== undefined && (
        <Lightbox
          mainSrc={images[lightboxImageIndex]}
          nextSrc={images[(lightboxImageIndex + 1) % images.length]}
          prevSrc={
            images[(lightboxImageIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => {
            setLightboxImageIndex(0);
            setLightboxForRoomTypeIndex(undefined);
          }}
          onMovePrevRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxImageIndex(
              (lightboxImageIndex + images.length + 1) % images.length
            )
          }
        />
      )}
    </Layout>
  );
};

export default withPrismicPreview(RoomPage);
